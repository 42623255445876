<!--
/**
 *@author peng wang
 *@date 2023/2/20
 *  现场人员管理 充电桩巡检详情
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageTopButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
        <ykc-button
          v-rbac="'maintain:personnel:pileInspection:export'"
          type="plain"
          @click="doExportFile">
          导出
        </ykc-button>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card">
        <div slot="header">
          <span>基础信息</span>
        </div>
        <div class="baseInfo">
          <div class="content">
            <div class="top">
              <div>
                <span class="label">电站名称:</span>
                <span class="value">
                  {{ dealData(detailInfo.stationName) }}
                </span>
              </div>
              <div>
                <span class="label">电站地址:</span>
                <span class="value">
                  {{ dealData(detailInfo.stationAddress) }}
                </span>
              </div>
              <div>
                <span class="label">设备台数:</span>
                <span class="value">
                  {{ dealData(detailInfo.equipmentNum) }}
                </span>
              </div>
              <div>
                <span class="label">电桩类型:</span>
                <span class="value">
                  {{ dealData(detailInfo.pileType == '1' ? '直流' : '交流') }}
                </span>
              </div>
              <template v-if="detailInfo.pileType === 1">
                <div>
                  <span class="label">总电度值:</span>
                  <span class="value">
                    {{ dealData(detailInfo.totalElectricalValue) }}
                  </span>
                </div>
                <div>
                  <span class="label">软件版本:</span>
                  <span class="value">
                    {{ dealData(detailInfo.softwareVersion) }}
                  </span>
                </div>
                <div>
                  <span class="label">电子档案:</span>
                  <span class="value">
                    {{ dealData(detailInfo.electronicFiles) || '——' }}
                  </span>
                </div>
                <div>
                  <span class="label">参数设置检查:</span>
                  <span class="value">
                    {{ getFilterData(detailInfo.parameterSetCheck) }}
                  </span>
                </div>
                <div>
                  <span class="label">模块配置数量:</span>
                  <span class="value">
                    {{ dealData(detailInfo.moduleConfigNum) || '——' }}
                  </span>
                </div>

                <div>
                  <span class="label">模块工作数量:</span>
                  <span class="value">
                    {{ dealData(detailInfo.moduleWorkNum) || '——' }}
                  </span>
                </div>
                <div>
                  <span class="label">交流电压/频率:</span>
                  <span class="value">
                    {{ getFilterData(detailInfo.exchangeVoltage) }}
                  </span>
                </div>
                <div>
                  <span class="label">进线电源相间电压:</span>
                  <span class="value">
                    {{ getFilterData(detailInfo.incomePowerSupplyVoltage) }}
                  </span>
                </div>
                <div>
                  <span class="label">车型:</span>
                  <span class="value">
                    {{ dealData(detailInfo.carModel) }}
                  </span>
                </div>
              </template>
              <div>
                <span class="label">附件:</span>
                <viewer :images="detailInfo.filePath">
                  <img
                    class="form-item-img"
                    v-for="src in detailInfo.filePath"
                    :src="src"
                    :key="src.title" />
                </viewer>
              </div>
            </div>
          </div>
        </div>
      </YkcDetailPlus>
    </div>
    <div class="flexCenter">
      <ykc-detail :isLine="true" :titles="[`桩体外部`]" :className="'left'">
        <div slot="cvBody">
          <div
            class="form-detail flex"
            :style="{ height: detailInfo.pileType === 1 ? '685px' : 'auto' }">
            <ykc-form class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo1.chargeFireProtectionFlag"
                :label="'充电位消防设置是否齐全,桩位环境是否干净'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.chargeFireProtectionProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.pileSurfaceMarkingFlag"
                :label="'充电桩表面标示是否明显，充电桩上有无异物等'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.pileSurfaceMarkingProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.pileBaseFlag"
                :label="'充电桩底座是否有损坏，裂痕，倾斜，晃动现象'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.pileBaseProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.chargeGunFlag"
                :label="'充电枪及线缆是否正常，且是否存在损坏或残留水灰尘和杂物情况'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.chargeGunProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.dustProofNetFlag"
                :label="'防尘网是否积聚灰尘，是否及时清理'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.dustProofNetProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <template v-if="detailInfo.pileType === 2">
                <ykc-detail-item
                  :tagStatus="detailInfo1.distributionBoxLockFlag"
                  :label="'配电柜门锁是否损坏，指示灯是否正常，配电柜门是否正常'">
                  <ykc-form-item label="问题处理">
                    <ykc-input
                      type="textarea"
                      :disabled="true"
                      v-model="detailInfo1.distributionBoxLockProblem"></ykc-input>
                  </ykc-form-item>
                </ykc-detail-item>
              </template>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail
        v-if="detailInfo.pileType === 1"
        :isLine="true"
        :titles="[`桩体内部`]"
        :className="'left'">
        <div slot="cvBody">
          <div class="form-detail flex">
            <ykc-form v-if="detailInfo.pileType === 1" class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo1.protectLightningSystemFlag"
                :label="'防雷系统是否正常，接地线连接有无松动'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.protectLightningSystemProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.distributionBoxLockFlag"
                :label="'配电柜门锁是否损坏，指示灯是否正常，配电柜门是否正常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.distributionBoxLockProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.distributionBoxComponentFlag"
                :label="'配电柜内部断路器等器件是否有烧毁，烧黑的现象，节点端子是否良好'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.distributionBoxComponentProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.boxInnerComponentFlag"
                :label="'箱体内部器件电缆等是否整齐，松动老化等问题，标识是否清楚'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.boxInnerComponentProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.incomeCableFlag"
                :label="'进线电缆是否适合充电桩的电压和电流'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.incomeCableProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.fireproofMudFlag"
                :label="'电缆进线口是否用防火泥密封'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.fireproofMudProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
            <ykc-form v-else :label-position="'left'" label-width="68px" class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo1.pileInnerModuleFlag"
                :label="'桩体内模块是否正常，有无报警等异常情况'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.pileInnerModuleProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.circuitBoardConnectFlag"
                :label="'主板与计费等电路板连接线有无松动，板面是否积尘'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.circuitBoardConnectProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.moduleFanFlag"
                :label="'模块风扇积尘是否严重，散热及风道是否正常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.moduleFanProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
    </div>
    <div class="flexCenter">
      <ykc-detail :isLine="true" :titles="[`功能模块`]" :className="'left'">
        <div slot="cvBody">
          <div
            class="form-detail flex"
            :style="{ height: detailInfo.pileType === 1 ? '490px' : 'auto' }">
            <ykc-form class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo1.pileInnerModuleFlag"
                :label="'桩体内模块是否正常，有无报警等异常情况'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.pileInnerModuleProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.circuitBoardConnectFlag"
                :label="'主板与计费等电路板连接线有无松动，板面是否积尘'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.circuitBoardConnectProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.moduleFanFlag"
                :label="'模块风扇积尘是否严重，散热及风道是否正常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.moduleFanProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail
        v-if="detailInfo.pileType === 1"
        :isLine="true"
        :titles="[`操作检查`]"
        :className="'left'">
        <div slot="cvBody">
          <div class="form-detail flex">
            <ykc-form class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo2.useUserCardFlag"
                :label="'使用用户卡对每一台充电桩进行检查是否正常充电'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.useUserCardProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.pileDisplayScreenFlag"
                :label="'充电桩显示屏是否正常亮起，指示灯是否亮起'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.pileDisplayScreenProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.checkScanCodeFlag"
                :label="'检查扫码充电功能是否正常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.checkScanCodeProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.checkGunInterfaceFlag"
                :label="'检查充电枪接口是否能够正常使用'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.checkGunInterfaceProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.screenChargeValueFlag"
                :label="'在正常充电情况下，显示屏充电参数数值是否正常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.screenChargeValueProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
    </div>
    <div class="flexCenter">
      <ykc-detail :isLine="true" :titles="[`控制系统`]" :className="'left'">
        <div slot="cvBody">
          <div class="form-detail flex">
            <ykc-form class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo1.checkCircuitBoardFlag"
                :label="'检查充电桩控制电路板，内部各个元器件有无老化'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.checkCircuitBoardProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.checkPileVoltageFlag"
                :label="'检查充电桩供电端电压，对地电压，是否正常值范围'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.checkPileVoltageProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.emergencyStopButtonFlag"
                :label="'急停按钮功能是否正常，桩外故障灯是否正常亮起'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.emergencyStopButtonProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.displayScreenPageFlag"
                :label="'显示屏操作页面是否有报警故障'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.displayScreenPageProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo1.cabinetFanFlag"
                :label="'机柜风扇运转是否正常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo1.cabinetFanProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
      <ykc-detail :isLine="true" :titles="[`充电记录`]" :className="'left'">
        <div slot="cvBody">
          <div class="form-detail flex" style="height: 392px">
            <ykc-form :label-position="'left'" class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo2.chargeExceptionStopFlag"
                :label="'充电异常停止原因'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.chargeExceptionStopProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item :tagStatus="detailInfo2.errorRecordFlag" :label="'故障记录是否异常'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.errorRecordProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
    </div>
    <div class="flexCenter flexCenterLast" v-if="detailInfo.pileType === 1">
      <ykc-detail :isLine="true" :tips="tips" :titles="[`箱体`]">
        <div slot="cvBody">
          <div class="form-detail flex">
            <ykc-form class="list-left" style="margin-right: 80px">
              <ykc-detail-item
                :tagStatus="detailInfo2.housingStateFlag"
                :label="'外壳是否无变形腐蚀'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.housingStateProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.threeProofingFlag"
                :label="'防水，防尘，防腐蚀性是否完好'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.threeProofingProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.boxBaseFlag"
                :label="'箱体底座是否有损坏，和裂痕，倾斜，晃动现象'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.boxBaseProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.cableCrossBoxFlag"
                :label="'进出电缆穿越箱体处是否封堵'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.cableCrossBoxProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
            <ykc-form class="list-left">
              <ykc-detail-item
                :tagStatus="detailInfo2.signIdentificationFlag"
                :label="'标志标识是否清晰'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.signIdentificationProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.equipmentOperationFlag"
                :label="'设备运行有无异常异味'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.equipmentOperationProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item :tagStatus="detailInfo2.doorLockFlag" :label="'门锁是否关闭'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.doorLockProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
              <ykc-detail-item
                :tagStatus="detailInfo2.internalWiringFlag"
                :label="'线内接线是否牢固'">
                <ykc-form-item label="问题处理">
                  <ykc-input
                    type="textarea"
                    :disabled="true"
                    v-model="detailInfo2.internalWiringProblem"></ykc-input>
                </ykc-form-item>
              </ykc-detail-item>
            </ykc-form>
          </div>
        </div>
      </ykc-detail>
    </div>
  </scroll-layout>
</template>

<script>
  import { dealData, offlineExport } from '@/utils';
  import { omManagementApi } from '@/service/apis';

  export default {
    name: 'pileInspectionDetail',
    components: {},
    data() {
      return {
        type: '', // platform 平台商户 跳转直联电站 ，union 跳转互联电站
        detailId: '',
        tips: false,
        isEdit: false,
        showDrawer: false,
        detailInfo: {},
        detailInfo1: {},
        detailInfo2: {},
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    created() {
      const { id } = this.$route.query;
      this.detailId = id;
      this.getDetail(id);
    },
    computed: {},
    methods: {
      dealData,
      /**
       * @desc 查询详情
       * */
      getDetail(id) {
        // 编辑回显
        if (id) {
          omManagementApi
            .queryForPageDetail({ id })
            .then(res => {
              console.log('res++++', res.detail1);
              this.detailInfo = res;
              this.detailInfo1 = res.detail1;
              this.detailInfo2 = res.detail2;
            })
            .catch(() => {});
        }
      },
      /**
       * 数据处理 例：--、1-- 、-1-   -前后无值  补0
       */
      getFilterData(val) {
        return val
          .split('-')
          .map(item => {
            return Number(item);
          })
          .join('-');
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'charge_pile_inspect',
          jsonNode: {
            id: this.detailId,
          },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .fleet-match-content {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-flow: column;
    .box-card {
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .label {
            color: #000 !important;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            color: #9a9a9a;
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .flexCenter {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .cardview-box {
      width: 100%;
      .cardview-body {
        .form-detail {
          width: 100%;

          ::v-deep .el-form {
            width: 100%;

            .ykc-detail-item-box {
              width: 100%;
              > .el-form-item {
                width: 100%;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                justify-content: space-between;

                > .el-form-item__label {
                  width: auto !important;
                  margin-bottom: 10px;
                }
                > .el-form-item__content {
                  width: 100%;
                  margin-left: 0 !important;

                  > .ykc-form-item {
                    width: 100%;
                    .el-form-item {
                      display: flex;
                      flex-flow: row nowrap;
                      align-items: flex-start;
                      justify-content: space-between;
                      margin-bottom: 0;

                      .el-form-item__label {
                        width: 60px;
                      }
                      .el-form-item__content {
                        width: 100%;
                        height: 60px;
                        margin-left: 0 !important;
                        > .ykc-input,
                        .el-textarea__inner {
                          height: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    > :nth-child(2) {
      margin-left: 10px;
    }
  }

  .form-item-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
</style>
